<template>
  <a-modal title="员工列表" class="page-row-item" width="80%" v-model="visible" @cancel="handleClose">
    <div class="page-search">
      <div class="page-search-item">
        <span class="page-search-item-label">工种/专业</span>
        <projectCascader
          :demandId.sync="query.demandId"
          :projectDepartmentId.sync="query.projectDepartmentId"
          :projectId.sync="query.projectId"
          style="width: 100%"
        />
      </div>
      <div class="page-search-item">
        <span class="page-search-item-label">员工姓名</span>
        <a-input v-model="query.realName" allowClear placeholder="员工姓名" />
      </div>
      <div class="page-search-item">
        <span class="page-search-item-label">联系电话</span>
        <a-input v-model="query.phone" allowClear placeholder="联系电话" />
      </div>
      <div class="page-search-item last-item" v-perms:upload-salary="'search'">
        <a-button type @click="handleReset">重置</a-button>
        <a-button type="primary" @click="$refs.table.initPage()">查询</a-button>
      </div>
    </div>
    <div class="page-content">
      <p-table
        ref="table"
        :columns="tableColumns"
        :sourceData="getDataApi"
        :row-selection="rowSelection"
        @change="handleTableChange"
        :scroll="{ x: 1200, y: 400 }"
        row-key="dataIndex"
      >
        <template slot="headerLeft">
          <div style="width: 100%; display: flex; justify-content: space-between; align-items: center">
            <div class="left" style="display: flex; align-items: center">
              <a-button
                type="primary"
                :disabled="!selectedRows.length"
                @click="handleAddAll"
                v-perms:upload-salary="'upload-all'"
              >批量上传</a-button
              >
              <div style="margin-left: 12px;color: red">平台限制发薪年龄，如果需要为65岁以上人员发薪请联系平台客服</div>
            </div>
            <div class="right">
              <a-button type="link" @click="handleDownLoad" v-perms:upload-salary="'export'">导出表格模板</a-button>
              <a-upload
                name="file"
                :showUploadList="false"
                :customRequest="customRequest"
                v-perms:upload-salary="'import'"
              >
                <a-button type="link" style="color: green">导入薪资数据表</a-button>
              </a-upload>
            </div>
          </div>
        </template>
        <template slot="operation" slot-scope="text, record">
          <div class="operation_btn" @click="handleUpload(record)" v-perms:upload-salary="'upload'">上传工资条</div>
        </template>
      </p-table>
    </div>
    <template slot="footer">
      <div style="width: 100%; display: flex; justify-content: flex-end">
        <a-button type="" @click="handleClose">取消</a-button>
      </div>
    </template>
    <salaryModal ref="salaryModal" @success="handleGetData" />
  </a-modal>
</template>

<script>
import { getCommissionList, exportSalaryTemplate, importByExcel } from '@/api/recruitUse'
import projectCascader from './project-cascader.vue'
import salaryModal from './salary-modal.vue'

export default {
  name: 'UserList',
  inject: ['pageType'],
  components: {
    salaryModal,
    projectCascader
  },
  data () {
    return {
      visible: false,
      modalTitle: '',
      query: {},
      selectedRowKeys: [],
      selectedRows: [],
      modelData: [],
      enterpriseMoney: 0,
      tableColumns: [
        {
          title: '序号',
          dataIndex: 'key',
          customRender: (text, record, index) => record.selectIndex || ''
        },
        {
          title: '姓名',
          dataIndex: 'realName'
        },
        {
          title: '注册账户ID',
          dataIndex: 'userName'
        },
        {
          title: '性别',
          dataIndex: 'sex',
          customRender: (text) => (text == 0 ? '男' : '女')
        },
        {
          title: '年龄',
          dataIndex: 'age'
        },
        {
          title: '联系电话',
          dataIndex: 'phone'
        },
        {
          title: '岗位薪资',
          dataIndex: 'salary'
        },
        {
          title: '工种/专业',
          dataIndex: 'positionName'
        },
        {
          title: '上一次结算周期',
          width: 240,
          dataIndex: 'date'
        },
        // {
        //   title: '发薪状态',
        //   dataIndex: 'status',
        //   customRender: text => (text === 0 ? '未发放' : '已发放')
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 240,
          scopedSlots: { customRender: 'operation' }
        }
      ]
    }
  },
  computed: {
    rowSelection () {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.tableChange,
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.status == 1 || record.age > 65
          }
        })
      }
    }
  },
  methods: {
    tableChange (selectedRowKeys, selectedRows) {
      console.log(selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.$nextTick(() => {
        this.$refs.table.tableData.forEach((item, index) => {
          const selectIndex = this.selectedRowKeys.indexOf(item.dataIndex)
          if (selectIndex !== -1) {
            this.$set(this.$refs.table.tableData[index], 'selectIndex', selectIndex + 1)
          } else {
            this.$set(this.$refs.table.tableData[index], 'selectIndex', '')
          }
        })
      })
    },
    showModal () {
      this.visible = true
      this.$nextTick(() => {
        this.$refs.table.initPage()
      })
    },
    handleReset () {
      this.query = {}
      this.$refs.table.initPage()
    },
    handleClose () {
      this.$refs.table.refresh()
      this.visible = false
    },
    getDataApi (params) {
      this.handleTableChange()
      return getCommissionList({
        ...params,
        ...this.query,
        flag: this.pageType
      }).then((res) => {
        return Promise.resolve({
          ...res,
          data: res.data.map((item, index) => ({
            ...item,
            dataIndex: index,
            selectIndex: '',
            id: `${item.userId},${item.demandId}`
          }))
        })
      })
    },
    handleGetData () {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.$refs.table.initPage()
    },
    handleAddAll () {
      const data = this.selectedRows.map((item, index) => ({
        realName: item.realName,
        demandId: item.demandId,
        userId: item.userId,
        userName: item.userName,
        phone: item.phone
      }))
      this.$refs.salaryModal.open(data, false)
    },
    handleUpload ({ ...rowItem }) {
      const data = [
        {
          realName: rowItem.realName,
          demandId: rowItem.demandId,
          userId: rowItem.userId,
          userName: rowItem.userName,
          phone: rowItem.phone
        }
      ]
      this.$refs.salaryModal.open(data, false)
    },
    handleDownLoad () {
      const { current: pageIndex, pageSize } = this.$refs.table.pagination
      this.$spin.show()
      exportSalaryTemplate({
        pageCmd: {
          pageIndex,
          pageSize
        },
        qry: {
          ...this.query,
          flag: this.pageType,
          demandIdList: this.selectedRowKeys.length
            ? this.selectedRowKeys
            : this.$refs.table.tableData.reduce((keys, item, index) => {
                if (item.age > 65) {
                  return keys
                }
                return [...keys, index]
              }, [])
        }
      })
        .then((res) => {
          const blob = new Blob([res], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          })
          const a = document.createElement('a')
          const URL = window.URL || window.webkitURL
          const href = URL.createObjectURL(blob)
          a.href = href
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          window.URL.revokeObjectURL(href)
        })
        .finally(() => {
          this.$spin.hide()
        })
    },
    customRequest (file) {
      this.$spin.show()
      const form = new FormData()
      form.append('file', file.file)
      importByExcel(form, this.pageType)
        .then((res) => {
          this.$refs.salaryModal.open(res.data, true)
        })
        .finally(() => {
          this.$spin.hide()
        })
    },
    handleToPay () {
      window.open('/home/balance?isRecharge=true')
    },
    handleTableChange () {
      this.selectedRowKeys = []
      this.selectedRows = []
    }
  }
}
</script>

<style lang="less">
.page-row-item {
  .page-search {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    &-item {
      flex: 1;
      margin-right: 24px;
      display: flex;
      align-items: center;
      & + .last-item {
        margin-right: 0;
        justify-content: flex-end;
      }
      &-label {
        position: relative;
        display: inline-block;
        margin-right: 24px;
        white-space: nowrap;
        &::after {
          content: '：';
          display: block;
          position: absolute;
          width: 10px;
          height: 100%;
          right: -12px;
          top: 0;
        }
      }
    }
  }
  .page-content {
    padding: 0 0 40px 0;
  }
}
</style>
